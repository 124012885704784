document.addEventListener('DOMContentLoaded', function(){
    initEdition()
});

//Start Burger
const burgerOpenBtn = document.getElementById("nav-burger");
const burgerMenuList = document.querySelector(".nav__main");
// const body = document.querySelector("body");

const burgerOpen = function (event) {
  event.preventDefault();
  burgerOpenBtn.classList.toggle("opened");
  burgerMenuList.classList.toggle("opened");
  // body.classList.toggle("noscroll");
};
//End Burger

if($('.nav__list-item.has-children').length > 0){
    $('.nav__list-item.has-children.active').addClass('menu-open');
}

$('.nav-link').on('click',function(e){
  var parent = $(this).parent('.has-children');
  if(parent.length > 0){
    e.preventDefault();
    var children = parent.find('.nav-treeview');
    console.log('hello');
    if(parent.hasClass('menu-open')){
      parent.removeClass('menu-open');
      children.slideUp(400);
    }else{
      parent.addClass('menu-open');
      children.slideDown(400);
    }
  }
});

$('.nav-link[role="tab"]').on('click',function(){
    var current = $(this);
    if(!current.hasClass('active')){
        var parent = current.parents('.nav-tabs');
        var prev_active_link = parent.find('.nav-link.active');
        var prev_active_tab = prev_active_link.attr('href');
        var current_tab = current.attr('href');
        prev_active_link.removeClass('active');
        $(prev_active_tab).removeClass('active show');
        current.addClass('active');
        $(current_tab).addClass('active show');
    }
});

$('.btn-group .dropdown-toggle').on('click',function(){
    var aria_expanded = $(this).prop('aria-expanded');
    var parent = $(this).parent('.btn-group');
    var dropdown = $(this).next('.dropdown-menu');

    if(aria_expanded == true){
        parent.removeClass('show');
        dropdown.removeClass('show');
        $(this).prop('aria-expanded', false);
    }else{
        parent.addClass('show');
        dropdown.addClass('show');
        $(this).prop('aria-expanded', true);
    }
});

const initEdition = () => {

    let element = document.getElementById('blog_description');
    if ( typeof element === 'undefined' ) {
        return ;
    }

    ClassicEditor
        .create( element )
        .then( editor => {
            window.editor = editor;
        } )
        .catch( err => {
            console.error( err.stack );
        } );
}
